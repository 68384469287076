<template>
  <v-card color="white" elevation="0">
    <v-card-text>
      <v-form>
        <v-select
          v-model="user.role"
          :label="$t('component.userDialog.fields.role.title')"
          :items="roles"
          item-text="name"
          item-value="id"
          return-object
        ></v-select>
        <v-checkbox
          v-model="user.isActive"
          :label="$t('component.userDialog.fields.isActive.title')"
        ></v-checkbox>
        <increment-input
          v-model="user.invitesLeft"
          :disabled="user.role.name == 'Sudo'"
          :min="0"
          :max="5"
        ></increment-input>
      </v-form>
    </v-card-text>
    <v-card-actions
      ><v-spacer></v-spacer
      ><v-btn @click="log(user)">Log</v-btn></v-card-actions
    >
  </v-card>
</template>
<script>
import IncrementInput from "../../components/FormField/IncrementInput.vue";
export default {
  props: ["parameter"],
  components: { IncrementInput },
  data() {
    return {
      user: {},
      original: {},
    };
  },
  computed: {
    roles() {
      return this.$store.getters["utils/roles"];
    },
    userRole() {
      return this.user.role;
    },
  },

  methods: {
    save() {
      alert("todo");
    },
    log(e) {
      console.log(e);
    },
  },
  created() {
    if (this.parameter != null && this.parameter["user"] != null) {
      this.$store.dispatch("utils/loadRoles", {});
      this.user = this.parameter["user"];
      this.original = { ...this.user };
    }
  },
};
</script>