<template>
  <!-- <v-btn @click="decrement" small depressed>-</v-btn> -->
  <v-text-field
    class="in"
    type="number"
    v-model="data"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    @change="limitValue"
  >
    <template v-slot:prepend>
      <v-btn icon @click="decrement" :disabled="disableDecrement">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </template>
    <template v-slot:append-outer
      ><v-btn icon @click="increment" :disabled="disableIncrement">
        <v-icon>mdi-plus</v-icon>
      </v-btn></template
    >
  </v-text-field>
  <!-- <v-btn @click="increment" small depressed>+</v-btn>
  </v-input> -->
</template>
<script>
export default {
  props: {
    value: { required: false, type: Number, default: 0 },
    label: { required: false },
    rules: { required: false, type: Array },
    disabled: { required: false, default: false, type: Boolean },
    min: { required: false, type: Number },
    max: { required: false, type: Number },
  },
  data() {
    return {
      data: 0,
    };
  },
  computed: {
    disableDecrement() {
      if (this.min != null) return this.disabled || this.data <= this.min;
      return this.disabled;
    },
    disableIncrement() {
      if (this.max != null) return this.disabled || this.data >= this.max;
      return this.disabled;
    },
    disableInput() {
      return this.disableIncrement || this.disableDecrement;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.data = value;
        }
      },
    },
  },
  methods: {
    increment() {
      this.data++;
      this.$emit("change", this.value);
    },
    decrement() {
      this.data--;
      this.$emit("change", this.value);
    },
    limitValue() {
      var d = this.data;
      if (this.max != null && d > this.max) {
        this.data = this.max;
      } else if (this.min != null && d < this.min) {
        this.data = this.min;
      }
    },
  },
};
</script>

<style scoped>
.in {
  max-width: 150px;
  align-content: center;
}
</style>